<template>
  <TTView>
    <VRow>
      <VCol cols="1">
        <TTBtn
          color="tt-light-mono-100"
          icon
          :to="{
            name : Names.R_LEARNING_COURSE_V2_CONTENT_COPYRIGHT
          }"
        >
          <VIcon>$prev</VIcon>
        </TTBtn>
      </VCol>
      <VCol>
        <h1>Copyright</h1>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <h3>{{ $t('edit_copyright.sub_header') }}</h3>
        <h4 v-if="copyright">
          {{ copyright.id }}
        </h4>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <VForm
          v-if="copyright"
          ref="form"
          v-model="isValid"
          lazy-validation
        >
          <VRow>
            <VCol cols="12">
              <TTTextField
                v-model="copyright.name"
                :label="$t('create_copyright.name')"
                :rules="[rules.required]"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <label>Pre-roll:</label>
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <VBtn
                icon
                @click="edit.prerollUrl = !edit.prerollUrl"
              >
                <VIcon small>
                  $edit
                </VIcon>
              </VBtn>
              <a
                :href="copyright.prerollUrl"
                :title="copyright.prerollUrl"
              >
                {{ getFileNameFromUrl(copyright.prerollUrl) }}
              </a>
            </VCol>
          </VRow>
          <VRow v-show="edit.prerollUrl">
            <VCol>
              <UploadImage
                v-model="newCopyright.prerollUrl"
                :rules="[rules.videoOrNull]"
                label="Pre-roll"
                outlined
                :accept="'video'"
                class="tt-light-mono-4"
                :disabled-always="false"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <label>Post-roll:</label>
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <VBtn
                icon
              >
                <VIcon
                  small
                  @click="edit.postrollUrl = !edit.postrollUrl"
                >
                  $edit
                </VIcon>
              </VBtn>
              <a
                :href="copyright.postrollUrl"
                :title="copyright.postrollUrl"
              >
                {{ getFileNameFromUrl(copyright.postrollUrl) }}
              </a>
            </VCol>
          </VRow>
          <VRow v-show="edit.postrollUrl">
            <VCol>
              <UploadImage
                v-model="newCopyright.postrollUrl"
                :rules="[rules.videoOrNull]"
                label="Post-roll"
                outlined
                :accept="'video'"
                class="tt-light-mono-4"
                :disabled-always="false"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <label>Watermark:</label>
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <VBtn
                icon
                @click="edit.watermarkUrl = !edit.watermarkUrl"
              >
                <VIcon small>
                  $edit
                </VIcon>
              </VBtn>
              <a
                :href="copyright.watermarkUrl"
                :title="copyright.watermarkUrl"
              >
                {{ getFileNameFromUrl(copyright.watermarkUrl) }}
              </a>
            </VCol>
          </VRow>
          <VRow v-show="edit.watermarkUrl">
            <VCol>
              <UploadImage
                v-model="newCopyright.watermarkUrl"
                :rules="[rules.imageOrNull]"
                label="Watermark"
                outlined
                :accept="'image'"
                class="tt-light-mono-4"
                :disabled-always="false"
              />
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <TTBtn
                :disabled="!isValid || loading"
                @click="submit"
              >
                {{ $t('edit_copyright.submit') }}
              </TTBtn>
            </VCol>
          </VRow>
        </VForm>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <VAlert
          v-show="contentItems.values.length"
          shaped
          dense
          dark
          type="warning"
        >
          Обратите внимание, изменения в Copyright не изменяют контент, к которому этот Copyright привязан.<br>
          Для изменения контента после внесения изменений в Copyright, необходимо перезалить контент.
          Список  связанного контента можно посмотреть ниже
        </VAlert>

        <TTDataTable
          v-if="contentItems.values.length"
          :headers="headers"
          :items="contentItems.values"
          :loading="loading"
          disable-pagination
          disable-sort
        >
          <template #item.actions="{ item }">
            <VTooltip left>
              <template #activator="{ on, attrs }">
                <TTBtn
                  icon
                  fab
                  v-bind="attrs"
                  :to="getLink(item)"
                  v-on="on"
                >
                  <VIcon>
                    $externalLink
                  </VIcon>
                </TTBtn>
              </template>
              <span>{{ $t('edit_copyright.external_link') }}</span>
            </VTooltip>
          </template>
        </TTDataTable>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>

import { required, fileTypeOrNull, getFileNameFromUrl } from '@front.backoffice/common';
import UploadImage from '../../components/UploadImage.vue';

export default {
  name: 'EditCopyright',
  components: {
    UploadImage,
  },
  inject: ['Names'],
  data() {
    return {
      isValid: false,
      loading: false,
      copyright: null,
      edit: {
        prerollUrl: false,
        postrollUrl: false,
        watermarkUrl: false,
      },
      newCopyright: {
        prerollUrl: '',
        postrollUrl: '',
        watermarkUrl: '',
      },
      headers: [
        { text: 'Id', value: 'id', width: '40%' },
        { text: 'Название', value: 'title', width: '50%' },
        { text: 'Действия', value: 'actions', width: '10%' },
      ],
      contentItems: { values: [], loading: false },
    };
  },
  computed: {
    rules() {
      return {
        required: required(),
        imageOrNull: fileTypeOrNull('image', 'или изображение или пустая строка'),
        videoOrNull: fileTypeOrNull('video', 'или видео или пустая строка'),
      };
    },
    copyrightId() {
      return this.$route.params.copyrightId;
    },
    prerollUrl() {
      return this.edit.prerollUrl ? this.newCopyright.prerollUrl : this.copyright.prerollUrl;
    },
    postrollUrl() {
      return this.edit.postrollUrl ? this.newCopyright.postrollUrl : this.copyright.postrollUrl;
    },
    watermarkUrl() {
      return this.edit.watermarkUrl ? this.newCopyright.watermarkUrl : this.copyright.watermarkUrl;
    },
  },
  async created() {
    this.copyright = await this.fetchCopyright(this.copyrightId);
  },
  methods: {
    getFileNameFromUrl,
    async fetchCopyright(id) {
      try {
        this.loading = true;
        const { copyright } = await this.$di.api.TtCourse.getCopyright({ id });
        return copyright;
      } catch (e) {
        this.$di.notify.errorHandler(e);
        return null;
      } finally {
        this.loading = false;
      }
    },
    async submit() {
      if (this.$refs.form.validate()) {
        await this.updateCopyright();
      }
    },
    async updateCopyright() {
      try {
        this.loading = true;
        const id = await this.$di.api.TtCourse.updateCopyright({
          id: this.copyrightId,
          copyright: {
            name: this.copyright.name,
            prerollUrl: this.prerollUrl,
            postrollUrl: this.postrollUrl,
            watermarkUrl: this.watermarkUrl,
          },
        });
        this.$di.notify.snackSuccess(this.$t('edit_copyright.result_success'));
        this.contentItems.loading = true;
        const { copyrightContentItems } = await this.$di.api.TtCourse.copyrightContentItems({ id: this.copyrightId });
        this.contentItems.values = copyrightContentItems;
        this.contentItems.loading = false;
        return id;
      } catch (e) {
        this.$di.notify.errorHandler(e);
        return null;
      } finally {
        this.loading = false;
      }
    },
    getLink(item) {
      return {
        name: this.Names.R_LEARNING_COURSE_V2_CONTENT_VIDEO_EDIT,
        params: { contentId: item.id },
      };
    },
  },
};
</script>
